import React from 'react'
import Layout from '../components/layout'
import Seo from "../components/Seo";

import {Box, Flex, Text} from "@chakra-ui/react";
import UpdatedStatsShowcase from "../components/Sections/updatedStatsShowcase";
import LazyHydrate from "react-lazy-hydration";
import TutorsSlider from "../components/Sections/Sliders/tutorsSlider";
import TutorSchoolLogos from "../components/Sections/tutorSchoolLogos";
import ThreeColumn from "../components/Collections/threeColumn";
import Template from "../components/Interactive/video";
import TestimonialsSlider from "../components/Sections/Sliders/testimonialsSlider";
import {graphql} from "gatsby";
import Hero from "../sections/main/hero";
import TwoColumn from "../components/Collections/twoColumn";
import ProductBox from "../components/Cards/productBox";
import {useCookies} from "react-cookie";
import statsImgRatio from "../assets/images/stats-img-ratio.png";
import statsImgStudents from "../assets/images/stats-img-students.png";
import statsImgReferral from "../assets/images/stats-img-referral.png";

const PRODUCT_COOKIE_NAME = "lpProductChoice";
const LP_URL_COOKIE_NAME = "lpSlug";


const HeroSection = ({data}) => {
	
	// override the parts we don't need
	const heroSection = data?.sanityHomepage?.heroSection;
	heroSection.ctaBtn.button.textLink = "#choices";
	heroSection.heroTitle = [{align: "left", text: <React.Fragment>{data.goalName}<sup>®</sup> tutors</React.Fragment>}, {align: "left", text:"that open doors"}];
	heroSection.heroDescription._rawContent[0].children[0].text = "Individualized tutoring targeted to your goals, timeline, and personality";
	heroSection.className = "ppcHero";
	heroSection.ctaCname = "funnel_page_hero_cta_button"
	return <Hero data={heroSection} />;
}

const WhyEnhancedPrepSection = ({data}) => {
	return <Flex className='pillar-page-section'>
		<Flex className='pillar-page-section-contents' marginTop={{base: '60px', lg: '125px'}} marginBottom={{base: '60px', lg:'100px'}}>
			<Text as='h3' fontSize={{base: '26px', lg: '36px'}}>Why Enhanced Prep?</Text>
			<Box width='100%'>
				<UpdatedStatsShowcase />
			</Box>
		</Flex>
	</Flex>;
}

const TutorSection = (allSanityTutor) => {
	
	return <Flex className='pillar-page-section' backgroundColor='#F4F4F4'>
		<Flex className='pillar-page-section-contents' marginTop={{base: '30px', lg: '60px'}} marginBottom={{base: '75px', lg: '95px'}}>
			<Text as='h3' fontSize={{base: '26px', lg: '36px'}} marginBottom={{base: "30px", lg: "30px"}} textAlign='center'>Tutors with Superior Credentials & Expertise</Text>
			<LazyHydrate whenVisible>
				<TutorsSlider data={allSanityTutor?.data?.nodes} noShuffle={true}/></LazyHydrate>
			
			<Text as='h4' fontSize={{base: '20px', lg: '24px'}} marginTop={{base: '30px', lg: '60px'}} marginBottom={{base: "30px", lg: "30px"}} textAlign='center'>Tutors are graduates of top universities</Text>
			<TutorSchoolLogos />
		</Flex>
	</Flex>;
}

const ProductChoiceSection = (data) => {
	const [cookies, setCookie, removeCookie] = useCookies([LP_URL_COOKIE_NAME]);
	let titleText = "Flexible " + data.data.goalName + "® prep that gets results";
	let productData1 = {
		key: "1",
		productName: "1-on-1",
		productTag: "MOST POPULAR",
		mostPopular: true,
		description: "The ultimate support system and personalized plan",
		price: "$1500",
		onCtaClick: () => {
			setCookie(PRODUCT_COOKIE_NAME, "1-on-1", {expires: dateTomorrow, path: '/'});
			if (typeof window === undefined) {
				return;
			}
			window.location = "/lead-form";
		},
		ctaText: "Learn more",
		startingAt: true,
		productSections: []
	}
	
	let productData2 = {
		key: "2",
		productName: "Small group",
		productTag: "SHARE & SAVE",
		mostPopular: false,
		description: "Weekly group tutoring help plus independent study materials",
		price: "$950",
		onCtaClick: () => {
			setCookie(PRODUCT_COOKIE_NAME, "Small group", {expires: dateTomorrow, path: '/'});
			if (typeof window === undefined) {
				return;
			}
			window.location = "/lead-form";
		},
		ctaText: "Learn more",
		startingAt: false,
		productSections: []
	}
	
	let productData3 = {
		key: "3",
		productName: "Self-guided",
		productTag: "DO IT YOURSELF",
		mostPopular: false,
		description: "A 6-month subscription to our premium online study guide",
		price: "$399",
		onCtaClick: () => {
			if (typeof window === undefined) {
				return;
			}
			window.location = `https://study.com/buy/course/ep/${cookies.lpSlug}.html`
		},
		ctaText: "Learn more",
		startingAt: false,
		productSections: []
	}
	
	return <Flex id="choices" className='pillar-page-section' bgImage={`url(https://cdn.sanity.io/images/coco4jcd/production/3039df5cfd53ab7804cd2b69e058538addafa3b3-208x144.svg?w=208&h=144&auto=format)`} bgRepeat='repeat' bgSize='auto' width="100%">
		<Flex className='pillar-page-section-contents' marginTop={{base: '40px', lg: '50px'}} marginBottom="60px">
			
			<Text as='h3' color='white' fontSize={{base: '26px', lg: '36px'}} marginBottom={{base: "30px", lg: "40px"}} textAlign='center'>
				{ titleText }</Text>
		
			<Flex className='product-bar' align="center" justify="center" gap="15px" marginBottom="15px" width="100%" >
				<ProductBox data={productData1} />
				<ProductBox data={productData2} />
				<ProductBox data={productData3} />
			</Flex>
			
			<Text color="white" fontSize='24px' marginTop="15px" textAlign='center'>
				Can't decide? <a test-id="funnel_page_schedule_a_call" data-cname="funnel_page_schedule_a_call" className="schedule-call-link" href="/lead-form"
			       onClick={() => removeCookie(PRODUCT_COOKIE_NAME, {path: '/'})}>
				<u>Schedule a free consultation call</u></a>.
			</Text>
		</Flex>
	</Flex>;
}

const NumbersSection = (data) => {
	
	let copiedData = {
		 _type: "columnThree",
		 marginTop: 0,
		 marginBottom: 0,
		 maxWidth: null,
		 noMobileVerticalPadding: false,
		 centerAlignVertical: true,
		 content1: {
			content: [
				{
					_type: "mainImage",
					alt: "school",
					dataCname: null,
					asset: {
						gatsbyImageData: {
							images: {
								sources: [statsImgRatio],
								fallback: {
									src: statsImgRatio,
									srcSet: `${statsImgRatio} 139w`,
									sizes: "(min-width: 139px) 139px, 100vw"
								}
							},
							layout: "constrained",
							backgroundColor: "#f7f5b9",
							width: 139,
							height: 133
						}
					}
				},
				{
					_type: "optionTitle",
					fullBarColor: null,
					fullBarIconColor: null,
					shownDesktop: true,
					shownMobile: true,
					tag: "h2",
					id: null,
					marginAdjuster: {
						_type: "marginAdjuster",
						marginTopDesk: 0,
						marginBotDesk: 0,
						marginTopMobile: 0,
						marginBotMobile: 0
					},
					typeVisibility: null,
					selectStyle: null,
					_rawTexts: [
						{
							_key: "e3b09be43ea6",
							_type: "coloredTitle",
							align: "center",
							color: {
								color: "primary700",
								value: "#006600"
							},
							desktopSize: 48,
							font: "sourceSansPro700",
							lineHeight: 20,
							text: "95%"
						}
					],
					_rawBrushColor: null,
					brushSize: null,
					_rawFullBarColor: null,
					_rawFullBarIconColor: null,
					_rawIcon: null,
					icon: null
				},
				{
					_type: "blockContent",
					fontSize: "18px",
					fontSizeMobile: 18,
					lineHeight: null,
					desktopWidth: 196,
					marginBottom: null,
					pTextMarginBottom: null,
					alignItems: "flex-start",
					dataCname: null,
					_rawContent: [
						{
							_key: "a4a4e906dc6b",
							_type: "block",
							children: [
								{
									_key: "d5e87e2a2cac0",
									_type: "span",
									marks: [
										"AlignCenter"
									],
									text: "Students accepted into one of their top schools"
								}
							],
							markDefs: [],
							style: "normal"
						}
					],
					visibilityAdjuster: {
						_type: "visibilityAdjuster",
						shownDesktop: true,
						shownMobile: true,
						desktopMin: "md"
					}
				}
			]
		},
		 content2: {
			content: [
				{
					_type: "mainImage",
					alt: "globe",
					dataCname: null,
					asset: {
						gatsbyImageData: {
							images: {
								sources: [statsImgStudents],
								fallback: {
									src: statsImgStudents,
									srcSet: `${statsImgStudents} 139w`,
									sizes: "(min-width: 139px) 139px, 100vw"
								}
							},
							layout: "constrained",
							backgroundColor: "#0c750e",
							width: 139,
							height: 134
						}
					}
				},
				{
					_type: "optionTitle",
					fullBarColor: null,
					fullBarIconColor: null,
					shownDesktop: true,
					shownMobile: true,
					tag: "h3",
					id: null,
					marginAdjuster: {
						_type: "marginAdjuster",
						marginTopDesk: 0,
						marginBotDesk: 0,
						marginTopMobile: 0,
						marginBotMobile: 0
					},
					typeVisibility: null,
					selectStyle: null,
					_rawTexts: [
						{
							_key: "4704524da7cd",
							_type: "coloredTitle",
							align: "center",
							color: {
								color: "primary700",
								value: "#006600"
							},
							desktopSize: 46,
							font: "sourceSansPro700",
							lineHeight: 20,
							text: "17,500+"
						}
					],
					_rawBrushColor: null,
					brushSize: null,
					_rawFullBarColor: null,
					_rawFullBarIconColor: null,
					_rawIcon: null,
					icon: null
				},
				{
					_type: "blockContent",
					fontSize: "18px",
					fontSizeMobile: 18,
					lineHeight: null,
					desktopWidth: 196,
					marginBottom: null,
					pTextMarginBottom: null,
					alignItems: "flex-start",
					dataCname: null,
					_rawContent: [
						{
							_key: "5188894f5c5c",
							_type: "block",
							children: [
								{
									_key: "6b1e1395d942",
									_type: "span",
									marks: [
										"AlignCenter"
									],
									text: "Students served by Enhanced Prep"
								}
							],
							markDefs: [],
							style: "normal"
						}
					],
					visibilityAdjuster: {
						_type: "visibilityAdjuster",
						shownDesktop: true,
						shownMobile: true,
						desktopMin: "md"
					}
				}
			]
		},
		 content3: {
			content: [
				{
					_type: "mainImage",
					alt: "horn",
					dataCname: null,
					asset: {
						gatsbyImageData: {
							images: {
								sources: [statsImgReferral],
								fallback: {
									src: statsImgReferral,
									srcSet: `${statsImgReferral} 139w`,
									sizes: "(min-width: 139px) 139px, 100vw"
								}
							},
							layout: "constrained",
							backgroundColor: "#faf4b7",
							width: 138,
							height: 133
						}
					}
				},
				{
					_type: "optionTitle",
					fullBarColor: null,
					fullBarIconColor: null,
					shownDesktop: true,
					shownMobile: true,
					tag: "h3",
					id: null,
					marginAdjuster: {
						_type: "marginAdjuster",
						marginTopDesk: 0,
						marginBotDesk: 0,
						marginTopMobile: 0,
						marginBotMobile: 0
					},
					typeVisibility: null,
					selectStyle: null,
					_rawTexts: [
						{
							_key: "47d9df5bbc9f",
							_type: "coloredTitle",
							align: "center",
							color: {
								color: "primary700",
								value: "#006600"
							},
							desktopSize: 46,
							font: "sourceSansPro700",
							lineHeight: 20,
							text: "3"
						}
					],
					_rawBrushColor: null,
					brushSize: null,
					_rawFullBarColor: null,
					_rawFullBarIconColor: null,
					_rawIcon: null,
					icon: null
				},
				{
					_type: "blockContent",
					fontSize: "18px",
					fontSizeMobile: 18,
					lineHeight: null,
					desktopWidth: 230,
					marginBottom: null,
					pTextMarginBottom: null,
					alignItems: "flex-start",
					dataCname: null,
					_rawContent: [
						{
							_key: "fad19e9e0d7e",
							_type: "block",
							children: [
								{
									_key: "5d4332f1a2ce",
									_type: "span",
									marks: [
										"AlignCenter"
									],
									text: "Avg. number of new students referred by satisfied families"
								}
							],
							markDefs: [],
							style: "normal"
						}
					],
					visibilityAdjuster: {
						_type: "visibilityAdjuster",
						shownDesktop: true,
						shownMobile: true,
						desktopMin: "md"
					}
				}
			]
		}
	}
	
	return <Flex className='pillar-page-section' width="100%">
		<Flex width="100%" className='pillar-page-section-contents tutoring-choice-stats' marginTop="60px" marginBottom={{base: '60px', lg: '130px'}}>
			<LazyHydrate whenVisible><ThreeColumn stretch={true} data={copiedData}></ThreeColumn></LazyHydrate>
		</Flex>
	</Flex>
}

const StudentSuccessSection = ({data, title}) => {
	
	return <Flex className='pillar-page-section' backgroundColor="#E9F8FB">
		<Flex className='pillar-page-section-contents' marginTop="60px" marginBottom="60px">
			<Text as='h3'
					display='block'
					fontSize={{base: '26px', lg: '36px'}}
					lineHeight={{base: "40px", md: "50px"}}
					fontFamily='merriweather'
					textAlign='center'
			>
				{title}
			</Text>
			
			<Flex className="student-success-columns" marginTop={{base: "45px", lg: "60px"}} width={{base: "100%", sm: "80%"}} align="center" justify="center">
				<TwoColumn data={data}></TwoColumn>
			</Flex>
		</Flex>
	</Flex>;
}

const ThreePillarsSection = ({data}) => {
	
	data.allSanityPillarCards.nodes[1].pillars.content1.content[0].bgColor = "rgb(0, 111, 130)";
	data.allSanityPillarCards.nodes[1].pillars.content2.content[0].bgColor = "rgb(0, 111, 130)";
	data.allSanityPillarCards.nodes[1].pillars.content3.content[0].bgColor = "rgb(0, 111, 130)";

	return <Flex className='pillar-page-section three-pillar-section'>
		<Flex className='pillar-page-section-contents' marginTop={{base: '60px', lg: '90px'}} marginBottom={{base: '60px', lg: '130px'}}>
			
			<Box marginBottom='30px'>
				<Text as='h4' fontFamily='Merriweather' fontSize={{base: '27px', lg: '30px'}} textAlign="center">How Enhanced Prep Maximizes
					Scores</Text>
			</Box>
			<Text as='span' fontFamily='sourceSansPro' fontSize={{base: '18px', lg: '21px'}} textAlign='center' marginBottom='20px'>Our learning model,
				the Three Pillars, helps students maximize their standardized test scores.</Text>
			
			<LazyHydrate whenVisible><ThreeColumn stretch={true} data={data?.allSanityPillarCards?.nodes[1]?.pillars}></ThreeColumn></LazyHydrate>
		</Flex>
	</Flex>;
}

const TestimonialSection = ({data}) => {
	return <Flex className='pillar-page-section ppc-testimonial-section' backgroundColor='#E9F9FB'>
		<Flex className='pillar-page-section-contents' marginTop={{base: '67px', lg: '86px'}} marginBottom={{base: '44px', lg: '86px'}}>
			<Box marginBottom={{base: '10px', lg: '40px'}}>
				<Text as='h3' fontSize={{base: '26px', lg: '36px'}}>Testimonials</Text>
			</Box>
			<LazyHydrate whenVisible><Template data={{videoUrl: "https://fast.wistia.com/embed/medias/tfc6cbxk91.jsonp", desktopWidthPixels: "600"}} locatorString="testimonial_video"/></LazyHydrate>
			<LazyHydrate whenVisible><TestimonialsSlider data={data?.allSanityReview?.nodes[0]} /></LazyHydrate>
		</Flex>
	</Flex>;
}

let dateTomorrow = new Date();
dateTomorrow.setDate(dateTomorrow.getDate() + 1);


export default function TutoringChoicesTemplate({data, pageContext, location}) {
	
	const {slug, header, footer, allSanityTutor} = pageContext
	data.goalName = slug.toUpperCase();
	const [cookies, setCookie, removeCookie] = useCookies([LP_URL_COOKIE_NAME]);

	setCookie(LP_URL_COOKIE_NAME, slug, {expires: dateTomorrow, path: '/'});
	removeCookie(PRODUCT_COOKIE_NAME, {path: '/'});


	const seo = {
		title: 'Expert Tutoring for ' + data.goalName + ' | Enhanced Prep',
		metaDescription: 'Enhanced Prep matches you with expert ' + data.goalName + ' tutors who work with you on a personalized study plan. 95% of students are accepted into their top schools.',
		crazyEggScript: <script type="text/javascript" src="//script.crazyegg.com/pages/scripts/0114/9898.js" async="async" ></script>
	};
	
	if (typeof window === "undefined") {
		return null;
	}

	let studentSuccessTitle = null;
	if (slug === 'act') {
		allSanityTutor.nodes = allSanityTutor.nodes.filter(tutor => tutor.tutorName !== 'Rachel')
			.sort(
				(tutor1, tutor2) =>
					tutor1.tutorName === 'Amelia' ? -1 :
						tutor2.tutorName === 'Amelia' ? 1 : 0);
		studentSuccessTitle = "Seth increased his score and got into his dream school";
	} else if (slug === 'sat') {
		allSanityTutor.nodes = allSanityTutor.nodes.filter(tutor => tutor.tutorName !== 'Amelia')
			.sort(
				(tutor1, tutor2) =>
					tutor1.tutorName === 'Rachel' ? -1 :
						tutor2.tutorName === 'Rachel' ? 1 : 0);
		studentSuccessTitle = "Jackson pursued his passion & sailed towards his dream school";
	}

	
	return (
		<Layout header={header} footer={footer}>
			<Seo data={seo} location={location} />
			<Flex flexDirection='column' overflowX='hidden'>
				<HeroSection data={data} />
				<WhyEnhancedPrepSection data={data}/>
				<TutorSection data={allSanityTutor}/>
				<ProductChoiceSection data={data} />
				<NumbersSection data={data}/>
				<StudentSuccessSection data={pageContext.node.studentSuccess} title={studentSuccessTitle} />
				<ThreePillarsSection data={data} />
				<TestimonialSection data={data}/>
			</Flex>
		</Layout>
	)
}

export const query = graphql`
  query TutoringChoicesPageQuery {
    sanityHomepage {
		heroSection {
        _rawHeroBackgroundColor(resolveReferences: {maxDepth: 10})
        heroDescription {
          _rawContent(resolveReferences: {maxDepth: 10})
          desktopWidth
          marginBottom
        }
        heroTitle {
          align
          text
        }
        heroImage {
          alt
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        ctaBtn {
          button {
            ...SanityCtaButton
          }
        }
        sectionHeight
      }
    }
	  allSanityPillarCards {
		  nodes {
		  	...SanityPillarCards
		  }
	  }
  allSanityReview(filter: {id: {eq: "-3a3c139d-c07f-58b7-af6d-176fbc4049b8"}}) {
    nodes {
      reviews {
        about
        name
        quote
      }
      _id
      _key
      id
    }
  }
  }
`
