import React from "react";
import {Button, Flex} from "@chakra-ui/react";
import {BsCheck, BsX} from "react-icons/bs";

const ProductBoxHeader = ({ data }) => {
	
	if (data.mostPopular) {
		
		return (
		 <div style={{backgroundColor: "#006F82", color: 'white', padding: "15px", borderRadius: "6px 6px 0 0", alignItems: "center", width: "100%", textAlign: "center"}}>
			 <div className="product-name">{data.productName}</div>
			 <div className="product-tag">{data.productTag}</div>
		 </div>
		)
	}
	else {
		return (
		 <div style={{backgroundColor: "#E9F9FB", color: '#3d3d3d', padding: "15px", borderRadius: "6px 6px 0 0", alignItems: "center", width: "100%", textAlign: "center"}}>
			 <div className="product-name">{data.productName}</div>
			 <div className="product-tag">{data.productTag}</div>
		 </div>
		)
	}
}

const ProductFeaturesSection = ({section}) => {
	
	let features = [];
	let fontColor = "#3D3D3D";
	
	if (section.isActive) {
		section.features.map((feature) => {
			features.push(<Flex align="center" style={{fontSize: "18px", lineHeight: "18px", marginTop: "15px", fontFamily: "SourceSansPro"}}><BsCheck size="24px" color="green"/> {feature}</Flex>);
			return null;
		});
	}
	else {
		fontColor = "#A5A5A5";
		section.features.map((feature) => {
			features.push(<Flex align="center" style={{fontSize: "18px", lineHeight: "18px", marginTop: "15px", fontFamily: "SourceSansPro"}}><BsX size="24px" color="gray"/> {feature}</Flex>);
			return null;
		});
	}
	
	return (<div style={{color: fontColor}}>
		<div className="product-features__title" style={{fontSize: "21px", marginTop: "15px", fontFamily: "SourceSansPro"}}>{section.title}</div>
		<div className="product-features">
			{ features }
		</div>
	</div>);
}

export default function ProductBox({data}) {
	return (
	 <Flex flexDirection='column' flex='1' width={{sm: "312px"}}
			 style={{backgroundColor: "white", color: 'white', maxWidth: '320px', marginBottom: "15px", borderRadius: "6px"}}>
		<ProductBoxHeader data={data}/>
		 <div style={{color: "#3D3D3D", padding: "15px 15px 0 15px"}}>
			 <div className="product-description">{data.description}</div>
			 <Button
				  data-track-visible={""}
				  data-cname={"product_choice_option_" + data.key}
				  test-id={"lead_form_option_" + data.key}
				  key={"option" + data.key}
				  fontSize={{md: "18px"}}
				  lineHeight="28px"
				  margin="30px 0"
				  fontFamily="sourceSansPro600"
				  color={"white"}
				  padding="24px"
				  width="100%"
				  backgroundColor="primary"
				  borderRadius="6px"
				  _hover={{
					  filter: "brightness(1.1)",
					  transform: "scale(1.03)",
					  boxShadow: "0px 4px 6px -1px rgba(0, 127, 150, 0.2), 0px 2px 4px -1px rgba(0, 127, 150, 0.1)"
				  }}
				  onClick={data.onCtaClick}>{data.ctaText}</Button>
			 {	data.productSections?
				 data.productSections.map((section, idx) => {
					 return <ProductFeaturesSection section={section} />;
				 })
			 	: ""
			 }
		 </div>
	 </Flex>
	
	);
}
